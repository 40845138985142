/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../core/has.js";
import { fromValues as e } from "../../core/libs/gl-matrix-2/factories/vec3f64.js";
import { fromValues as r } from "../../geometry/support/aaBoundingBox.js";
function t(r, {
  isPrimitive: t,
  width: n,
  depth: o,
  height: s
}) {
  const c = t ? 10 : 1;
  if (null == n && null == s && null == o) return [c * r[0], c * r[1], c * r[2]];
  const i = e(n, o, s);
  let u;
  for (let e = 0; e < 3; e++) {
    const t = i[e];
    if (null != t) {
      u = t / r[e];
      break;
    }
  }
  for (let e = 0; e < 3; e++) null == i[e] && (i[e] = r[e] * u);
  return i;
}
const n = r(-.5, -.5, -.5, .5, .5, .5),
  o = r(-.5, -.5, 0, .5, .5, 1),
  s = r(-.5, -.5, 0, .5, .5, .5);
function c(e) {
  switch (e) {
    case "sphere":
    case "cube":
    case "diamond":
      return n;
    case "cylinder":
    case "cone":
    case "inverted-cone":
      return o;
    case "tetrahedron":
      return s;
    default:
      return;
  }
}
const i = ["butt", "square", "round"],
  u = [...i, "none"],
  a = ["miter", "bevel", "round"];
export { i as lineCaps, a as lineJoins, s as objectSymbolLayerBoundingBoxTetrahedron, n as objectSymbolLayerBoundingBoxUnitCube, o as objectSymbolLayerBoundingBoxUnitCylinder, c as objectSymbolLayerPrimitiveBoundingBox, t as objectSymbolLayerSizeWithResourceSize, u as pathCaps };